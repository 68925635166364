import { Box, Button, Checkbox, Divider, Flex, Stack, Text, Title, createStyles } from "@mantine/core"
import { useForm } from "@mantine/form"
import { AdminRole, PilotRole, UserRole } from "@soar/shared/types"
import { roleConfig } from "@soar/shared/utils"
import { ReactNode, useEffect } from "react"
import { BUTTON_GRADIENT_COLORS } from "../tokens"

function generateDefaultState() {
  const entries = UserRole.options.map((r) => [r, false])
  return Object.fromEntries(entries) as Record<UserRole, boolean>
}

function transformValues(values: Record<UserRole, boolean>) {
  return Object.entries(values).reduce((roles, entry) => {
    if (entry[1]) {
      return [...roles, entry[0] as UserRole]
    }
    return roles
  }, [] as UserRole[])
}

const useStyles = createStyles((theme) => ({
  borderCommon: {
    borderColor: theme.colors.gray[4],
    borderWidth: "1px",
  },
  footer: {
    borderTopStyle: "solid",
  },
  rolesPanel: {
    borderRightStyle: "solid",
    flexShrink: 0,
  },
  container: {
    borderTopStyle: "solid",
  },
}))

export function RolesForm({
  roles,
  children,
  onSubmit = () => {},
  onCancel = () => {},
  submitButtonLabel = "Edit roles",
  disableSubmission = false,
}: {
  disableSubmission?: boolean
  roles?: UserRole[]
  children?: ReactNode
  onSubmit?: (roles: UserRole[]) => void
  onCancel?: () => void
  submitButtonLabel?: string
}) {
  const { classes, cx } = useStyles()
  const form = useForm<Record<UserRole, boolean>>({
    initialValues: generateDefaultState(),
  })
  const changeRole = (role: UserRole, checked: boolean) => {
    const isSuperadmin = roles?.includes(UserRole.enum.SUPERADMIN) ?? false
    const toSuperadmin = role === UserRole.enum.SUPERADMIN
    if (!isSuperadmin && !toSuperadmin) {
      const newValues = Object.assign(
        {},
        form.values,
        {
          [AdminRole.enum.SUPERADMIN]: false,
          [AdminRole.enum.ADMIN]: false,
          [PilotRole.enum.STAFF_PILOT]: false,
          [PilotRole.enum.PILOT]: false,
          [PilotRole.enum.MECHANIC]: false,
        },
        {
          [role]: checked,
        },
      )
      form.setValues(newValues)
    }
  }

  const changeAdminRole = (role: AdminRole, checked: boolean) => {
    const newValues = Object.assign(
      {},
      form.values,
      {
        [AdminRole.enum.SUPERADMIN]: false,
        [AdminRole.enum.ADMIN]: false,
      },
      {
        [role]: checked,
      },
    )

    form.setValues(newValues)
  }

  const changePilotRole = (role: PilotRole, checked: boolean) => {
    const newValues = Object.assign(
      {},
      form.values,
      {
        [PilotRole.enum.STAFF_PILOT]: false,
        [PilotRole.enum.PILOT]: false,
        [PilotRole.enum.MECHANIC]: false,
      },
      {
        [role]: checked,
      },
    )

    form.setValues(newValues)
  }

  useEffect(() => {
    if (roles != null) {
      const newValues = Object.assign(generateDefaultState(), Object.fromEntries(roles.map((r) => [r, true])))
      form.setValues(newValues)
    }
  }, [roles])

  const rolesToRender = transformValues(form.values)

  return (
    <Box
      className={cx(classes.borderCommon, classes.container)}
      component="form"
      onSubmit={form.onSubmit((values) => {
        onSubmit(transformValues(values))
      })}
    >
      <Flex direction={{ base: "column", lg: "row" }}>
        <Box pl="md" pr="sm" py="md" w={375} className={cx(classes.borderCommon, classes.rolesPanel)}>
          <Box>{children}</Box>
          <Title order={4} my="md">
            {" "}
            Select Roles{" "}
          </Title>
          <Title order={5} bg="gray.1" mb="sm">
            Admin Roles
          </Title>
          <Stack>
            <Checkbox
              disabled
              checked={form.values.SUPERADMIN}
              label="Super Administrator"
              onChange={(event) => {
                changeRole(AdminRole.enum.SUPERADMIN, event.currentTarget.checked)
              }}
            />

            <Checkbox
              checked={form.values.ADMIN}
              label="Administrator"
              onChange={(event) => {
                changeRole(AdminRole.enum.ADMIN, event.currentTarget.checked)
              }}
            />
          </Stack>

          <Title order={5} bg="gray.1" mt="md" mb="sm">
            User Roles
          </Title>
          <Stack>
            <Checkbox
              checked={form.values.STAFF_PILOT}
              label={roleConfig.STAFF_PILOT.label}
              onChange={(event) => {
                changeRole(PilotRole.enum.STAFF_PILOT, event.currentTarget.checked)
              }}
            />

            <Checkbox
              checked={form.values.PILOT}
              label="Pilot"
              onChange={(event) => {
                changeRole(PilotRole.enum.PILOT, event.currentTarget.checked)
              }}
            />
            <Checkbox
              checked={form.values.MECHANIC}
              label={roleConfig.MECHANIC.label}
              onChange={(event) => {
                changeRole(PilotRole.enum.MECHANIC, event.currentTarget.checked)
              }}
            />
          </Stack>
        </Box>
        <Box px="sm" py="xs" style={{ flexGrow: 1 }}>
          <Title order={4}> Role description</Title>
          <Divider mt="xs" mb="md" />
          {rolesToRender.length > 0 ? (
            <Stack>
              {transformValues(form.values).map((role) => (
                <Box key={role}>
                  <Title order={6}>{roleConfig[role].label}</Title>
                  <Text>{roleConfig[role].description}</Text>
                </Box>
              ))}
            </Stack>
          ) : (
            <Text color="dimmed">Select roles to see their description...</Text>
          )}
        </Box>
      </Flex>
      <Flex justify="flex-end" gap="md" py="md" px="sm" className={cx(classes.borderCommon, classes.footer)}>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" disabled={disableSubmission} variant="gradient" gradient={{ ...BUTTON_GRADIENT_COLORS, deg: 180 }}>
          {submitButtonLabel}
        </Button>
      </Flex>
    </Box>
  )
}
